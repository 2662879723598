.new-address-on-file-form {
  max-width: 700px;

  .two-column-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
    row-gap: 10px;
  }
}

@media (max-width: 620px) {
  .new-address-on-file-form {
    .two-column-grid {
      grid-template-columns: 1fr;
    }
  }
}
